import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { InmuebleResponse } from '../models/InmueblesResponse';
import { Estado, Ciudad, BusquedaInmueble, TipoInmueble, TipoVenta } from '../models/InmuebleSearch.model.response';
import { AppConfig } from '../AppConfig';
import { InmueblesMasBuscados } from '../models/mas-buscados.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  headers = new HttpHeaders ({ 'Content-Type' : 'application/json'});
  endpoint: string = '';
  tipoInmueble : TipoInmueble=new TipoInmueble();
  busquedaInmueble: BusquedaInmueble=new BusquedaInmueble();
  ciudades: Ciudad=new Ciudad();
  tipoVenta : TipoVenta=new TipoVenta();
  constructor(private http: HttpClient, private appConfig: AppConfig) {
    this.endpoint = this.appConfig.getConfig('base_url');
  }

  obtenerEstados(): Observable<InmuebleResponse> {
    return this.http.post<InmuebleResponse>
    (`${this.endpoint}/bnt/inmuebles/inicio`, null, {headers: this.headers});
  }

  obtenerCiudades(estado: Estado): Observable<InmuebleResponse> {
    console.log("valor estado:"+estado);
    return this.http.post<InmuebleResponse>
    (`${this.endpoint}/bnt/inmuebles/ciudad`, {'estado': estado}, {headers: this.headers});
  }

  obtenerColonias(busquedaColonia: Ciudad[]): Observable<InmuebleResponse> {
    //console.log('Colonia::::::::::', busquedaColonia);
    return this.http.post<InmuebleResponse>
    (`${this.endpoint}/bnt/inmuebles/colonia`, {'ciudades': busquedaColonia}, {headers: this.headers});
  }

  obtenerInmuebles(busqueda: BusquedaInmueble, page?): Observable<any> {
    return this.http.post<any>
    (`${this.endpoint}/bnt/inmuebles/busquedaInmueble/page/${page}`, busqueda, {headers: this.headers});
  }

  obtenerMasBuscados(): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/bnt/inmuebles/masBuscados`,{}, { headers: this.headers });
  }

  obtenerTipoInmuebleFiltrados(tipoVenta: String): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/bnt/inmuebles/obtenerTipoInmuebleFiltrados`,{'tipoVenta': tipoVenta}, { headers: this.headers });
  }

  obtenerEstadoFiltro(tipoVenta: String): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/bnt/inmuebles/obtenerEstadoFiltro`,{'tipoVenta': tipoVenta}, { headers: this.headers });
    console.log("Entra al servicio de obetener filtro estados");
  }

  obtenerEstadoFiltroInmueble(idTipoInmueble: number): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/bnt/inmuebles/obtenerEstadoFiltroInmueble`,{'idTipoInmueble': idTipoInmueble}, { headers: this.headers });
    console.log("Entra al servicio de obteener filtro estados");
  }


  obtenerEst(tipoVenta: string,idTipoInmueble: number): Observable<any> {
    this.busquedaInmueble.idTipoInmuble=idTipoInmueble;
    var x = tipoVenta;
    var y: number = +x;

    this.busquedaInmueble.tipoVenta=y;
    console.log("info busqueda inmueble:"+this.busquedaInmueble);
    console.log("info busqueda tipoventa:"+this.busquedaInmueble.tipoVenta);
    console.log("info busqueda tipo immueble:"+this.busquedaInmueble.idTipoInmuble);
    return this.http.post<any>(`${this.endpoint}/bnt/inmuebles/obtenerEst`,{'tipoVenta': this.busquedaInmueble.tipoVenta,'idTipoInmuble': this.busquedaInmueble.idTipoInmuble}, { headers: this.headers });
    
  }

  ciudadEst(idEstado:number,tipoVenta: string,idTipoInmueble: number): Observable<any>{
    this.busquedaInmueble.idEstado=idEstado;
    this.busquedaInmueble.idTipoInmuble=idTipoInmueble;
    var x = tipoVenta;
    var y: number = +x;

    this.busquedaInmueble.tipoVenta=y;
    //console.log("info busqueda tipoventa:"+this.busquedaInmueble.idEstado);
    //console.log("info busqueda tipo immueble:"+this.busquedaInmueble.tipoVenta);
    //console.log("info busqueda tipo immueble:"+this.busquedaInmueble.idTipoInmuble);
    return this.http.post<any>(`${this.endpoint}/bnt/inmuebles/ciudadEst`,{'idEstado': this.busquedaInmueble.idEstado,'tipoVenta': this.busquedaInmueble.tipoVenta,'idTipoInmuble': this.busquedaInmueble.idTipoInmuble}, { headers: this.headers });
    
  }
  coloniaCiudad(idCiudad:number,idEstado:number,tipoVenta: string,idTipoInmueble: number): Observable<any>{
    this.busquedaInmueble.idEstado=idEstado;
    this.busquedaInmueble.idTipoInmuble=idTipoInmueble;
    var x = tipoVenta;
    var y: number = +x;

    this.busquedaInmueble.tipoVenta=y;
    
    this.ciudades.idCiudad=idCiudad;
    //console.log("info busqueda ciudad:"+this.ciudades.idCiudad);
    //console.log("info busqueda estado:"+this.busquedaInmueble.idEstado);
    //console.log("info busqueda tipo immueble:"+this.busquedaInmueble.tipoVenta);
    //console.log("info busqueda tipo immueble:"+this.busquedaInmueble.idTipoInmuble);
    return this.http.post<any>(`${this.endpoint}/bnt/inmuebles/coloniaCiudad`,{'idCiudad':this.ciudades.idCiudad,'idEstado': this.busquedaInmueble.idEstado,'tipoVenta': this.busquedaInmueble.tipoVenta,'idTipoInmuble': this.busquedaInmueble.idTipoInmuble}, { headers: this.headers });
    
  }
}
