
import { Inmueble } from './contactar-agente.model';
import {Promotor} from './InmuebleSearch.model.response';

export class DetalleInmuebleModel {
    folio: string;
    tipoInmueble: string;
    direccion: string;
    ciudad: string;
    estado: string;
    m2Terreno: string;
    m2Construccion: string;
    precio: string;
    estatus: string;
    descripcion:string;
    emailGerente: string;
    nombreGerente: string;
    promotor: Promotor;
    inmueble: Inmueble;
    comercializable: boolean;

    constructor() {
        this.folio = '';
        this.tipoInmueble = '';
        this.direccion = '';
        this.ciudad = '';
        this.estado = '';
        this.m2Terreno = '';
        this.m2Construccion = '';
        this.precio = '';
        this.estatus = '';
        this.descripcion ='';
        this.emailGerente = '';
        this.nombreGerente = '';
        this.comercializable = false;
    }
}
