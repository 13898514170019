export class CazaOportunidadModel {
    email : string;
    palabraClave : string;
    tipoInmueble : string;
    tipoVenta : string;
    idEstado : number;
    idCiudadList : string;
    coloniaList : string;
    rangoPrecioMin : number;
    rangoPrecioMax : number;
    rangoM2TerrenoMin : number;
    rangoM2TerrenoMax : number;
    rangoM2ConstruccionMin : number;
    rangoM2ConstruccionMax : number;
}

//pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"

/*[class.is-invalid]="correo.invalid && correo.touched"
                                      ngModel
                                      name="correo"
                                      #correo="ngModel"
                                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                      required*/
