import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { BannerComponent } from './pages/banner/banner.component';
import { DetalleInmuebleComponent } from './pages/detalle-inmueble/detalle-inmueble.component';

import { InmueblesComponent } from './pages/inmuebles/inmuebles.component';
import { EstaticoGenericoComponent } from './pages/estatico-generico/estatico-generico.component';
import {DirectorioContactoComponent} from './pages/directorio-contacto/directorio-contacto.component';

const ROUTES: Routes = [
  { path: 'home', component: BannerComponent },
  { path: 'inmuebles', component: InmueblesComponent },
  { path: 'detalle/:folio', component: DetalleInmuebleComponent},
  { path: '', pathMatch:'full', redirectTo: '/home'},
  { path: 'path/:pagina', component: EstaticoGenericoComponent },
  { path: 'contacto', component: DirectorioContactoComponent}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(ROUTES,{useHash: true})
  ],
  exports: [ RouterModule ] 
})
export class AppRoutingModule { }

//edcfv123
//SR2816245
