import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConfig} from '../AppConfig';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Estado} from '../models/InmuebleSearch.model.response';

@Injectable({
  providedIn: 'root'
})
export class DirectorioContactosService {

  headers = new HttpHeaders({'Content-Type': 'application/json'});
  endpoint: string = '';

  constructor(private http: HttpClient, public appConfig: AppConfig) {
    this.endpoint = this.appConfig.getConfig('base_url') + "/bnt/inmuebles";
  }

  getAllContactos(request: any): Observable<any> {
    return this.http.post(`${this.endpoint}/gerentes`, request, {headers: this.headers})
        .pipe(map((data: any) => {
          return data.respuesta;
        }))
  }

  getEstados(): Observable<Estado[]>{
    return this.http.post(`${this.endpoint}/getEstados`,null,{headers: this.headers})
        .pipe(map((data: any) => {
          return data.respuesta;
        }))
  }

}
