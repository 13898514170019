import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {ConfiguracionModel} from '../models/configuracion.model';
import {Observable} from 'rxjs';
import {AppConfig} from '../AppConfig';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {
  headers = new HttpHeaders({'Content-Type':'application/json'});
  endpoint = '';
  constructor(private http:HttpClient,private appConfig: AppConfig ) { 
    this.endpoint = this.appConfig.getConfig('base_url');
  }

  consultarConfiguracion(nombre:string):Observable<any>{
    console.log("configuracion servicio"+nombre);
    return this.http.post(`${this.endpoint}/bnt/inmuebles/configuracion/${nombre}/`,{headers:this.headers});
  
  }
  
}