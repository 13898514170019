import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { Estado, Ciudad } from '../../models/InmuebleSearch.model.response';
import { ContactarAgenteService } from '../../services/contactar-agente.service';
import { MotivoContacto, ContactarAgente } from '../../models/contactar-agente.model';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-contactar-agente',
  templateUrl: './contactar-agente.component.html',
  styleUrls: ['./contactar-agente.component.css']
})
export class ContactarAgenteComponent implements OnInit {

  @ViewChild('modalContacto', null) modalContacto: ElementRef;
  @ViewChild('modalRespuesta', null) modalRespuesta: ElementRef;
  error: any;
  @Input() detalle: any;
  @Input() idTipoSolicitud: number;
  estados: Estado[] = [];
  ciudades: Ciudad[] = [];
  Todos: string;
  numeroInvalido = true;
  formContacto: any = FormGroup;
  showForm:boolean;

  configuracion: any = {
    titulo: 'Registro Correcto',
    mensaje: 'Un agente se pondrá en contacto contigo',
    icono: 'fa fa-check-circle',
    color: 'text-success'
  }
  motivoContactos: MotivoContacto[] = [];
  public contacto: ContactarAgente = new ContactarAgente();
  constructor( private searchService: SearchService,
               private contactarAgenteServ: ContactarAgenteService,
               private date: DatePipe ) {
                this.obtenerEstados();
                this.obtenerMotivosContacto();
               }

  ngOnInit() {

    this.iniciarFormulario();

    this.contacto.idPrefContacto = 1;
    this.contacto.idEstado = null;
  }

  public iniciarFormulario() {
    this.formContacto = new FormGroup({
      nombreCliente: new FormControl(),
      correoECliente: new FormControl(),
      telCasaCliente: new FormControl(),
      telOficinaCliente: new FormControl(),
      idEstado: new FormControl(),
      idCiudad: new FormControl(),
      motivoContactoEntity: new FormControl(),
      idPrefContacto: new FormControl(),
      comentarioCliente: new FormControl()
    });
  }


  public obtenerEstados(): void {
    this.searchService.obtenerEstados()
      .subscribe( response => this.estados = response.respuesta.estados );
  }

  public estadoSeleccionado( estado:number ): void {
    //console.log(estado);
    if ( estado ) {
      this.searchService.obtenerCiudades( {idEstado: estado} as Estado )
        .subscribe( response => this.ciudades = response.respuesta.ciudades );
    }
  }

  public obtenerMotivosContacto(): void {
    this.motivoContactos = [];
    this.contactarAgenteServ.motivosContacto().subscribe(response => {
      this.motivoContactos = response;
      this.contacto.motivoContactoEntity = this.motivoContactos[0];
    });
  }

  public isNumberKey(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
}

  public validarNumeros() {
    if (this.formContacto.get('telCasaCliente').value.length < 7 && this.formContacto.get('telOficinaCliente').value.length < 7) {
      this.numeroInvalido = false;
    } else {
      this.numeroInvalido = true;
    }
  }

  public guardarSolicitud(): void {

    if(this.formContacto.invalid) {
      this.validarNumeros();
      Object.values(this.formContacto.controls).forEach(control => {
        // @ts-ignore
        control.markAsTouched();
      });
      return;
    }
    this.contacto.gerenteEntity.idGerente = this.detalle.promotor.gerenteEntity.idGerente;
    this.contacto.promotorEntity.idPromotor = this.detalle.promotor.idPromotor;
    this.contacto.idUsuario = 0;
    this.contacto.idStatusSolicitudDetalle = 1;
    this.contacto.idTipoSolicitud = this.idTipoSolicitud;
    this.contacto.inmuebleEntity.idInmueble = this.detalle.inmueble.idInmueble;
    this.contacto.idStatusSolicitud = 1;
    this.contacto.idDetalleStatus = 0;
    this.contacto.fechaCreacion = this.date.transform(new Date(), 'yyyy-MM-dd');
    this.contacto.fechaUltimaAct = this.date.transform(new Date(), 'yyyy-MM-dd');
    this.contacto.clienteEntity = null;

    if ( this.contacto.idSolicitud == null ) {
      this.contacto.idSolicitud = 0;
    }
    this.ciudades = [];
    this.contactarAgenteServ.guardarSolicitud(this.contacto).subscribe( response => {
      this.modalRespuesta.nativeElement.click();
      this.configuracion = {
        titulo: 'Registro Correcto',
        mensaje: 'Un agente se pondrá en contacto contigo',
        icono: 'fa fa-check-circle',
        color: 'text-success'
      };
      this.limpiar();
    }, err => {
      this.modalRespuesta.nativeElement.click();
      this.configuracion = {
        titulo: 'Error al registrar',
        mensaje: 'Ha ocurrido un error.',
        icono: 'fa fa-times',
        color: 'text-primary'
      };

    });
    this.modalContacto.nativeElement.click();

  }

  public limpiar() {
    this.contacto.idCiudad = null;
    this.contacto.idEstado = null;
    this.contacto.motivoContactoEntity = this.motivoContactos[0];
    this.ciudades = [];
    this.formContacto.reset({
      nombreCliente: '',
      correoECliente: '',
      motivoContactoEntity: this.contacto.motivoContactoEntity,
      telCasaCliente: '',
      telOficinaCliente: '',
      idPrefContacto: '1',
      idCiudad: this.ciudades,
      idEstado: null,
      comentarioCliente: ''
    });
  }

  public cerrar() {
    this.limpiar();
  }
}
