import { Injectable } from '@angular/core';
import {Ciudad} from '../models/InmuebleSearch.model.response';

@Injectable({
  providedIn: 'root'
})
export class ValidadoresCazaOportunidadService {

  constructor() { }

  crearArregloDeCadenas(arreglo: string[]): string{
    let cadena: string = '';
    //console.log(arreglo);
    if(arreglo.length > 0) {
      cadena = arreglo.reduce((acc, ids) => acc + ids + ',', '');
      cadena = cadena.slice(0, cadena.length - 1);
    }
    return cadena;
  }

  generarCadenaIdsCiudades(ciudades: Ciudad[]): string{
    let cadena: string = '';
    if(ciudades.length > 0){
      ciudades.forEach(ciudad => {
        cadena = cadena + ciudad.idCiudad.toString() + ',';
      });
      cadena = cadena.slice(0,cadena.length - 1);
    }
    return cadena;
  }

  generarListIdsCiudades(ciudades: Ciudad[]): number[] {
    let cadena: number[] = [];
    if (ciudades.length > 0) {
      ciudades.forEach(ciudad => {
        cadena.push(ciudad.idCiudad);
      });
    }
    
    return cadena;

  }

  modalOptios = {
    titulo: "CAZA OPORTUNIDADES",
    icono: {
      error: {logo: "fa fa-ban fa-4x", color: "#b01657"},
      exito: {logo: "fa fa-check fa-4x", color: "#00b023"},
      cargando: {logo: "fa fa-spinner fa-4x fa-spin", color: "#b01657"}
    },
    mensaje: {
      error: {header: "Error al realizar la operación.", footer: "A ocurrido un error, no se pudo guardar la información, por favor inténtalo más tarde. "},
      exito: {header: "Operación realizada con éxito.", footer: "La información se a registrado con éxito, gracias."},
      cargando: {header: "", footer: "Espera un momento por favor..."}
    }
  };

}
