import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    selector: '[routerTransform]',
})
export class RouterTransformDirective {


    constructor( private el: ElementRef, private router: Router ) {

    }

    @HostListener('click', ['$event'])
    public onClick( event ) {
        if ( event.target.tagName === 'A' ) {
            let url: string = event.target.getAttribute('href');
            //console.log(url);
            this.router.navigate([url.split('/')[0], url.split('/')[1]]);
            event.preventDefault();
        } else {
            return;
        }
    }
}
