import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { LasmasbuscadasService } from '../../services/lasmasbuscadas.service';
import { AppConfig } from '../../AppConfig';

declare var $: any;
@Component({
  selector: 'app-lasmasbuscadas',
  templateUrl: './lasmasbuscadas.component.html',
  styleUrls: ['./lasmasbuscadas.component.css']
})
export class LasmasbuscadasComponent implements OnInit {
  @ViewChildren('allThese') things: QueryList<any>;
  contenido: string = '';
  constructor( private lasmasbuscadas: LasmasbuscadasService,
    private appConfig: AppConfig ) {
      //this.banner = "http://localhost/dashboard/carrusel/carrusel.html";
      this.contenido = this.lasmasbuscadas.getLasMasBuscadas();
      
  }

  ngAfterViewInit() {
    this.things.changes.subscribe(t => {
      $('.carousel').carousel({
        interval: this.appConfig.getConfig('time.banner')
      });
    })
  }

  ngOnInit() {
  }

}

