import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RecomendarInmuebleModel} from '../models/recomendar-inmueble.model';
import {map} from 'rxjs/operators';
import {AppConfig} from '../AppConfig';

@Injectable({
  providedIn: 'root'
})
export class RecomendarInmuebleService {

  endpoint = '';
  headers = new HttpHeaders({'Content-Type':'application/json'});

  constructor(private http: HttpClient, private appConfig: AppConfig) {
    this.endpoint = this.appConfig.getConfig('base_url');
  }

  recomendarAUnAmigo(data: RecomendarInmuebleModel){
    return this.http.post(`${this.endpoint}/bnt/inmuebles/recomendar`,data,{headers: this.headers}).pipe( map ( (data: any) => {
      //console.log(data);
      return data;
    }))
  }

}
