import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AppConfig {
    private config: any = null;
    private env: boolean = true;
    //url_properties: string = 'http://localhost:7030/Inmuebles-backend/config.d/config/application_properties.json';
    //url_properties: string = 'http://localhost:7090/Inmuebles-0.0.1/config.d/config/application_properties.json';
    //url_properties: string = 'http://15.128.25.221:7819/Inmuebles-0.0.1/config.d/config/application_properties.json';
    //url_properties: string = 'https://lnxintrawls05p.unix.banorte.com:4442/Inmuebles-0.0.1/config.d/config/application_properties.json';
    //url_properties: string = 'http://15.128.25.231:7818/Inmuebles-0.0.1/config.d/config/application_properties.json';
    url_properties: string = 'https://www.inmueblesbanorte.com.mx/Inmuebles-0.0.1/config.d/config/application_properties.json';
    //url_properties: string = 'http://15.128.1.33:7819/Inmuebles-0.0.1/config.d/config/application_properties.json';
    //url_properties: string = 'http://localhost:7001/Inmuebles-0.0.1/config.d/config/application_properties.json';
    //url_properties: string = 'https://uxm910v1.qa.unix.banorte.com:4442/Inmuebles-0.0.1/config.d/config/application_properties.json';
    //url_properties: string = 'https://uxm909v1.unix.banorte.com:4448/Inmuebles-0.0.1/config.d/config/application_properties.json';
    //url_properties: string = 'https://lnxintrawls03d.dev.unix.banorte.com:4448/Inmuebles-0.0.1/config.d/config/application_properties.json';

    constructor( private http: HttpClient ) {

    }

    public getConfig( key: any ) {
        return this.config[key];
    }

    public getEnv( key: any ) {
        return this.env[key];
    }

    public load() {
        if( !this.env ) {
            this.url_properties = './config/config.json';
        }
        return new Promise<Boolean>( (resolve, reject) => {
            this.http.get( this.url_properties, {headers: new HttpHeaders({'Content-Type': 'application/json'})}).pipe(
                map( ( res: any ) => {
                    return res;
                }),
                catchError( e => {
                    reject( false );
                    throw new Error(e);
                })
            ).subscribe( response => {
                this.config = response;
                resolve( true );
            });
        });
    }
}
