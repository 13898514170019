import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MotivoContacto, ContactarAgente } from '../models/contactar-agente.model';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from '../AppConfig';

@Injectable({
  providedIn: 'root'
})
export class ContactarAgenteService {

  private httpHeaders: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  private endpoint: string;
  constructor( private http: HttpClient, private appConfig: AppConfig ) { 
    this.endpoint = appConfig.getConfig('base_url');
  }


  public motivosContacto(): Observable<MotivoContacto[]> {
    return this.http
      .post(`${this.endpoint}/bnt/inmuebles/motivos_contacto`,{}, { headers: this.httpHeaders })
      .pipe(
        map( (response: any) => {
          return response.respuesta as MotivoContacto[];
        })
    );
  }

  public guardarSolicitud( contacto: ContactarAgente ): Observable<ContactarAgente> {
    return this.http.post(`${this.endpoint}/bnt/inmuebles/nueva_solicitud`, contacto, { headers: this.httpHeaders })
      .pipe(
        map((response: any) => {
          return response.respuesta as ContactarAgente;
        }),
        catchError( e => {
          throw new Error(e);
        })
      )
  }
}
