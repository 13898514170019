import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfig } from '../../AppConfig';
import { Router } from '@angular/router';

const enlaces = new Map<string, string>();
enlaces.set('mas', 'Conoce Más');
enlaces.set('precios', 'Precios');
enlaces.set('pagos', 'Forma de Pago');
enlaces.set('info', 'Información Adicional');
enlaces.set('proceso', 'Proceso de Venta');
enlaces.set('contacto', 'Contactos');
enlaces.set('consejos', 'Consejos');
enlaces.set('faq', 'Preguntas Frecuentes');
enlaces.set('interes', 'Sitios de Interés');
enlaces.set('tutorial', 'Tutorial');
enlaces.set('quienes', 'Quienes Somos');
enlaces.set('quienesSomos2', 'Quienes Somos');
enlaces.set('glosario', 'Glosario');
enlaces.set('detalle', 'Detalle Inmueble');
enlaces.set('inmuebles', 'Búsqueda de Inmuebles');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent implements OnInit, OnChanges {
  
  @Output() valueChange = new EventEmitter();
  @Input() url: any;
  desplegado: boolean = false;
  breadcrumb: string[] = [];
  constructor( public appConfig: AppConfig,
               private router: Router ) {
                  
  }

  ngOnInit() {
  }

  ngOnChanges( changes:  SimpleChanges ) {
    let currentUrl = changes['url'];
    this.url = currentUrl.currentValue;
    this.routear(this.url);
  }

  public direccionar( key: string ): void {
    this.router.navigate(['/path', this.appConfig.getConfig( key )]);
  }

  valueChanged() {
    this.desplegado =! this.desplegado;
    this.valueChange.emit(this.desplegado);
  }

  public routear( ruta: string ): void {
    this.getValue( ruta );
  }

  public getValue( pattern: string ) {
    let contador = 0;
    this.breadcrumb = [];
    if ( pattern && pattern !== '/' ) {
      enlaces.forEach((value, key, enlaces) => {
        if( pattern.includes(key) ) {
          if ( pattern.includes('inmuebles') && !this.breadcrumb[0] || pattern.includes('detalle') && !this.breadcrumb[0]) {
            this.breadcrumb[0] = 'Conoce Más';
            contador =+ 1;
          }
          this.breadcrumb[contador] = value;
          if ( contador == 1 ) {
            return;
          }
          contador++;
        }
      })
    }
  }

}
