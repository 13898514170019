import { Component } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  url: string;
  title = 'FrontInmuebles';
  //7819 - 
  //81 - 7561
  abrirMenu: boolean = false;
  constructor( private route: Router ) {
    this.route.events.subscribe(event => {

      if (event instanceof RoutesRecognized){
        if(event['url']){
          this.url = event['url'];
        }
      }
    })
  }

  displayChange(event) {
    this.abrirMenu = event;
  }
}
