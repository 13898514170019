import { Component, OnInit } from '@angular/core';
import { CazaOportunidadModel } from '../../models/cazaOportunidad.model';
import { SearchService } from '../../services/search.service';
import { CargaInicial, Estado, BusquedaCiudad, Ciudad, BusquedaColonia, TipoInmueble } from '../../models/InmuebleSearch.model.response';
import { BusquedaInmueble } from '../../models/InmuebleSearch.model.response';
import { InmuebleResponse } from '../../models/InmueblesResponse';
import {CazaOportunidadService} from '../../services/caza-oportunidad.service';
import {FormBuilder, FormGroup, Validators, NgForm} from '@angular/forms';
import {ValidadoresCazaOportunidadService} from '../../services/validadoresCazaOportunidad.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { zip } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  option: any;

  //BUSQUEDA
  cargaInicial: CargaInicial = new CargaInicial();
  cargaciudad: BusquedaCiudad = new BusquedaCiudad();
  busquedaCiudad: BusquedaCiudad = new BusquedaCiudad();
  busquedaColonia: BusquedaColonia = new BusquedaColonia();
  coloniaFiltro: BusquedaColonia = new BusquedaColonia();
  ciudadesSeleccionadas: Ciudad[] = [];
  coloniasSeleccionadas: string[] = [];
  estados: Estado[];
  est: Estado = new Estado();
  cdd: Ciudad = new Ciudad();
  construccionRam: number;
  terrenoRam: number;
  tipoVenta:number = 0;
  convTipoVenta:String;
  formBusqueda: any = FormGroup;
  formCazaOportunidad: any = FormGroup;
  Todos:any;
  cleaning: boolean = false;
  payLoadCazaOportunidad: CazaOportunidadModel = new CazaOportunidadModel();

  selectedItem: TipoInmueble []= [];
  selectedEstado: Estado []=[];
  selectedEstadoInmueble:Estado[]=[];
  //CAZA OPORTUNIDAD
  CANCELAR: string = 'Cancelar';
  ACEPTAR: string = 'Aceptar';
  ENVIAR: string = 'Enviar';
  OK: string = 'OK';
  lblBtnCerrarModal: string = 'Cancelar';
  cazaOportunidadModel: CazaOportunidadModel = new CazaOportunidadModel();
  mensajeValidacionEmail: string;
  preciosCorrectos: boolean = true;
  procesandoCazaOportunidad: boolean = false;
  cazaOportunidadGuardada: boolean = false;
  cazaOportunidadFallida: boolean = false;
  confModal = {titulo: "", mensajeHeader: "", mensajeFooter: "", logo: "", colorLogo: ""};

  mtsTerrenoMin = [0,0,50,100,150,200,300,400,500,1000,1500,2000,5000,9999];
  mtsTerrenoMax = [0,49,99,149,199,299,399,499,999,1499,1999,4999,9999,9999];
  mtsConstruccionMin = [0,0,50,100,150,200,300,400,500,1000,1500,2000,5000,9999];
  mtsConstruccionMax = [0,49,99,149,199,299,399,499,999,1499,1999,4999,9999,9999];
  constructor(private searchService: SearchService,
              private cazaOportunidadService: CazaOportunidadService,
              private formBuilder: FormBuilder,
              private validarores: ValidadoresCazaOportunidadService,
              private routes: Router, private activeRoute: ActivatedRoute) {
    this.crearFormularioBusqueda();
    this.crearFormularioCazaOportunidad();
  }

  ngOnInit() {
    this.searchService.obtenerEstados().subscribe( (data) => {
      //console.log(data.respuesta);
      this.cargaInicial = data.respuesta;
      this.selectedItem = this.cargaInicial.tiposInmuebles;
      this.selectedEstado = this.cargaInicial.estados;
      this.configuracionModalCargando();
      this.lblBtnCerrarModal = this.CANCELAR;
      this.confModal.titulo = this.validarores.modalOptios.titulo;
      this.construccionRam = 0;
      this.terrenoRam = 0;
    });
  }

  getCiudades(esta: Estado) {
    console.log("valor estado"+esta);
    if(esta == null){
      return;
    }
    this.est = esta;
    if(this.ciudadesSeleccionadas.length > 0) {
      this.ciudadesSeleccionadas =  [];
      this.coloniasSeleccionadas = [];
      this.limpiar();
    }
    this.busquedaCiudad = new BusquedaCiudad();
    this.busquedaColonia = new BusquedaColonia();
    if (esta.nombre !== undefined) {
      //this.searchService.obtenerCiudades(esta as Estado).subscribe((data) => {
        this.searchService.ciudadEst((esta as Estado).idEstado,this.formBusqueda.get('tipoVenta').value,this.formBusqueda.get('tipoInmueble').value).subscribe((data)=>{
        console.log("data ciudad:"+data.respuesta);
        this.busquedaCiudad = data.respuesta;
        console.log("busqueda ciudad:"+this.busquedaCiudad);
      });
    } else {
      this.busquedaCiudad = new BusquedaCiudad();
      this.busquedaColonia = new BusquedaColonia();
      this.ciudadesSeleccionadas =  [];
      this.coloniasSeleccionadas = [];
    }
    /*if(esta.idEstado!==0){
      this.searchService.ciudadEst((esta as Estado).idEstado,this.formBusqueda.get('tipoVenta').value,this.formBusqueda.get('tipoInmueble').value).subscribe((data)=>{
        this.cargaciudad = new BusquedaCiudad();
        console.log("datos estado:"+data.respuesta);
        this.cargaciudad = data.respuesta;
        console.log("busqueda ciudad:"+this.cargaciudad);
      });
    }*/
  }

  getColonias(listSeleccionados: Ciudad[]) {
    /*console.log("datos ciudades"+listSeleccionados);
    this.searchService.obtenerColonias(listSeleccionados).subscribe((data) => {
      console.log("valor ciudad:"+this.cdd.idCiudad);
      this.busquedaColonia = data.respuesta;
    });*/
    this.searchService.coloniaCiudad(this.cdd.idCiudad,(this.formBusqueda.get('estado').value as Estado).idEstado,
    this.formBusqueda.get('tipoVenta').value,this.formBusqueda.get('tipoInmueble').value).subscribe((data)=>{
      this.busquedaColonia=data.respuesta;
    });
  }

  ciudadesSeleccion( item: Ciudad, isCom ? ) {
    if (item == null) {
      return;
    }
    this.cdd = item;
    this.busquedaColonia = new BusquedaColonia();
    this.coloniasSeleccionadas = [];
    if ( this.searchCiudad(this.ciudadesSeleccionadas, item) && !isCom ) {
      this.ciudadesSeleccionadas = this.ciudadesSeleccionadas.filter( (it) => {
        return it !== item && it !== null;
      });
    } else if ( !this.searchCiudad(this.ciudadesSeleccionadas, item) && item.nombre !== undefined && item != null && !this.cleaning ) {
      this.ciudadesSeleccionadas.push( item as Ciudad);
    }
    if (item.nombre === undefined) {
      this.ciudadesSeleccionadas =  [];
      this.coloniasSeleccionadas = [];
      this.busquedaColonia = new BusquedaColonia();
    }
    if (this.ciudadesSeleccionadas.length > 0) {
      this.getColonias(this.ciudadesSeleccionadas);
    }
    this.cleaning = false;
  }

  searchCiudad(lista: Ciudad[], item: Ciudad ) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < lista.length; i++) {
      if (lista[i].nombre === item.nombre) {
        return true;
      }
    }
    return false;
  }

  coloniasSeleccion( item: string, isCom? ) {
    if ( this.searchColonia(this.coloniasSeleccionadas, item) && !isCom ) {
      this.coloniasSeleccionadas = this.coloniasSeleccionadas.filter( (it) => {
        return it !== item && it !== '';
      });
    } else if ( !this.searchColonia(this.coloniasSeleccionadas, item )  && item !== 'Todos' && item != null ) {
      this.coloniasSeleccionadas.push( item );
    }

    if (item === 'Todos') {
      this.coloniasSeleccionadas = [];
    }
  }

  searchColonia(lista: string[], item: string ) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < lista.length; i++) {
      if (lista[i] === item) {
        return true;
      }
    }
    return false;
  }


  buscar() {
    let mensaje = {
      data: 'hola',
      message: 'mundo'
    };
    if (!this.formularioBusquedaEsValido()) {
      return;
    }
    let buscar: BusquedaInmueble = new BusquedaInmueble();
    // @ts-ignore
    buscar.idEstado =  (this.formBusqueda.get('estado').value as Estado).idEstado;
    // @ts-ignore
    buscar.idTipoInmuble = this.formBusqueda.get('tipoInmueble').value;
    buscar.listaCiudades = this.validarores.generarListIdsCiudades(this.ciudadesSeleccionadas);
    buscar.listaColonias = this.coloniasSeleccionadas;
    // @ts-ignore
    buscar.precioMinimo = +this.convertir(this.formBusqueda.get('precioMinimo').value);
    // @ts-ignore
    buscar.precioMaximo = +this.convertir(this.formBusqueda.get('precioMaximo').value);
    if (buscar.precioMinimo === 0 && buscar.precioMaximo === 0) {
      buscar.precioMinimo = null;
      buscar.precioMaximo = null;
    }
    // @ts-ignore
    buscar.palabraClave = this.formBusqueda.get('palabraClave').value;
    if (buscar.palabraClave === '') {
      buscar.palabraClave = null;
    }
    buscar.terreno = this.terrenoRam;
    buscar.contruccion = this.construccionRam;
    // @ts-ignore
    buscar.tipoVenta = this.formBusqueda.get('tipoVenta').value;
    localStorage.setItem('page', null);
    localStorage.setItem('filters', null);
    this.routes.navigate(['inmuebles'], {state: {buscar: btoa(JSON.stringify(buscar))},
    queryParams : {buscar: btoa(JSON.stringify(buscar))}, relativeTo: this.activeRoute});
    //this.searchService.obtenerInmuebles(buscar).subscribe(data => console.log(data));
  }

  limpiar() {

    this.cleaning = true;
    this.cdd = new Ciudad();
    this.est = new Estado();
    this.busquedaCiudad = new BusquedaCiudad();
    this.busquedaColonia = new BusquedaColonia();
    this.ciudadesSeleccionadas =  [];
    this.selectedItem= [];//AEA
    this.selectedEstado=[];//AEA
    this.coloniasSeleccionadas = [];
    this.preciosCorrectos = true;
    this.formBusqueda.reset({
      tipoVenta: 0,
      tipoInmueble: '',
      ciudad: [],
      terreno: 0,
      construccion: 0,
      precioMaximo: '$0',
      precioMinimo: '$0',
      palabraClave: ''
    });
  }

  setTerrenoRam(value) {
    this.terrenoRam = value;
  }

  setConstruccionRam(value) {
    this.construccionRam = value;
  }


  validarPrecioCorrecto(): boolean {
    this.preciosCorrectos = true;
    // @ts-ignore
    if ((this.convertir(this.formBusqueda.get('precioMinimo').value) > 0 || this.convertir(this.formBusqueda.get('precioMaximo').value) > 0) &&
        // @ts-ignore
        (this.convertir(this.formBusqueda.get('precioMinimo').value) > this.convertir(this.formBusqueda.get('precioMaximo').value))){
      return false;
    }
    return true;
  }

  guardarCazaOportunidad(){
    //console.log(this.formCazaOportunidad);
    //console.log(this.payLoadCazaOportunidad);
    if(!this.formularioCazaOportunidadEsValido()) {
      return;
    }
    if(!this.formularioBusquedaEsValido()){
      return;
    }
    this.configuracionModalCargando();
    this.procesandoCazaOportunidad = true;
    //console.log("Enviando casa oportunidad");
    this.crearPayLoadCazaOportunidad();
    //console.log("PAYLOAD CAZAOPORTUNIDAD: " + JSON.stringify(this.payLoadCazaOportunidad));
    this.lblBtnCerrarModal = this.ACEPTAR;
    this.cazaOportunidadService.guardarNuevaCazaOportunidad(this.payLoadCazaOportunidad).subscribe((data: InmuebleResponse) => {
      //console.log(data);
      //console.log(data.mensaje);
      if(data.mensaje == this.OK){
        this.cazaOportunidadGuardada = true;
        this.configuracionModalExito();
        this.procesandoCazaOportunidad = false;
        this.cazaOportunidadModel = new CazaOportunidadModel();
      }else{
        this.cazaOportunidadFallida = true;
        this.configuracionModalError();
        this.procesandoCazaOportunidad = false;
      }
    }, error => {
      //console.log(error);
      this.cazaOportunidadFallida = true;
      this.configuracionModalError();
      this.procesandoCazaOportunidad = false;
    });
  }

  /*
  Al cerrar el modal se reinician valroes de propiedades del modal
   */
  cerrarModalCazaOportunidad(){
    setTimeout(() =>{
      // @ts-ignore
      this.formCazaOportunidad.reset();
      this.mensajeValidacionEmail = "";
      this.cazaOportunidadGuardada = false;
      this.cazaOportunidadFallida = false;
      this.procesandoCazaOportunidad = false;
      this.lblBtnCerrarModal = this.CANCELAR;
    },1000)
  }

  /*
  Se crea objeto que es envida al servicio back para insertar una caza oportunidad a base de datos
   */
  crearPayLoadCazaOportunidad(){
    // @ts-ignore
    let estado: Estado = this.formBusqueda.get('estado').value as Estado;
    let mtrsConsMin = this.mtsConstruccionMin[this.construccionRam];
    let mtrsConsMax = this.mtsConstruccionMax[this.construccionRam];
    let mtrsTerrMin = this.mtsTerrenoMin[this.terrenoRam];
    let mtrsTerrMax = this.mtsTerrenoMax[this.terrenoRam];
    this.payLoadCazaOportunidad.coloniaList = this.validarores.crearArregloDeCadenas(this.coloniasSeleccionadas);
    // @ts-ignore
    this.payLoadCazaOportunidad.email = this.formCazaOportunidad.get('correo').value;
    this.payLoadCazaOportunidad.idCiudadList = this.validarores.generarCadenaIdsCiudades(this.ciudadesSeleccionadas);
    this.payLoadCazaOportunidad.idEstado = estado.idEstado;
    // @ts-ignore
    this.payLoadCazaOportunidad.palabraClave = this.formBusqueda.get('palabraClave').value;
    // @ts-ignore
    this.payLoadCazaOportunidad.rangoPrecioMax = this.convertir(this.formBusqueda.get('precioMaximo').value) == null ? 0 : this.convertir(this.formBusqueda.get('precioMaximo').value);
    // @ts-ignore
    this.payLoadCazaOportunidad.rangoPrecioMin = this.convertir(this.formBusqueda.get('precioMinimo').value);
    // @ts-ignore
    this.payLoadCazaOportunidad.tipoInmueble = this.formBusqueda.get('tipoInmueble').value;
    this.payLoadCazaOportunidad.rangoM2ConstruccionMin = mtrsConsMin;
    this.payLoadCazaOportunidad.rangoM2ConstruccionMax = mtrsConsMax;
    this.payLoadCazaOportunidad.rangoM2TerrenoMin = mtrsTerrMin;
    this.payLoadCazaOportunidad.rangoM2TerrenoMax = mtrsTerrMax;
    this.payLoadCazaOportunidad.tipoVenta = this.formBusqueda.get('tipoVenta').value;
  }

  /*
  Se valida formulario caza oportunidad
   */
  formularioCazaOportunidadEsValido():boolean{
    // @ts-ignore
    if(this.formCazaOportunidad.invalid){
      // @ts-ignore
      Object.values(this.formCazaOportunidad.controls).forEach(control => {
        // @ts-ignore
        control.markAsTouched();
        // @ts-ignore
        if(control.errors.hasOwnProperty("pattern"))
          this.mensajeValidacionEmail = "Correo no válido"
        else
          this.mensajeValidacionEmail = "Ingresa tu correo electrónico";
      });
      return false;
    }
    return true;
  }

  /*
  Se valida formulario búsqueda
   */
  formularioBusquedaEsValido():boolean{
    //mostrar los errores si se quiere lanzar el formulario sin pasar las validaciones
    // @ts-ignore
    if (this.formBusqueda.invalid) {
      // @ts-ignore
      Object.values(this.formBusqueda.controls).forEach(control => {
        // @ts-ignore
        control.markAsTouched();
      });
      return false;
    }
    if(!this.validarPrecioCorrecto()){
      this.preciosCorrectos = false;
      return false;
    }
    return true;
  }

  /*
  Se asignan elementos de la vista al forrmulario reactivo
   */
  crearFormularioBusqueda(){
    // @ts-ignore
    this.formBusqueda = this.formBuilder.group({
      tipoVenta: [0, '', ''],
      tipoInmueble: ['','',''],
      palabraClave: ['','',''],
      estado: ['',Validators.required,''],
      ciudad: ['','',''],
      //ciudad: this.formBuilder.array([]),
      colonia: ['','',''],
      //colonia: this.formBuilder.array([]),
      estatus: ['','',''],
      precioMinimo: ['$0',Validators.pattern(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/),''],
      precioMaximo: ['$0',Validators.pattern(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/),''],
      terreno: [0,'',''],
      construccion: [0,'','']
    });
  }

  /*
  Se asigna elemnto de la vista a formulario reactivo para caza oportunidad
   */
  crearFormularioCazaOportunidad(){
    // @ts-ignore
    this.formCazaOportunidad = this.formBuilder.group({
      correo: ['',[Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')],'']
    })
  }

  /*
  Get para obtener estado de propiedades de la propiedad 'estado'
   */
  get estadoInvalido(){
    // @ts-ignore
    return this.formBusqueda.get('estado').invalid && this.formBusqueda.get('estado').touched;
  }

  /*
  Get para obtener el estado de la propiedad 'correo'
   */
  get correoInvalido(){
    // @ts-ignore
    return this.formCazaOportunidad.get('correo').invalid && this.formCazaOportunidad.get('correo').touched;
  }

  /*
  Get para obtener el estado de la propiedad 'precioMinimo'
   */
  get precioMinimoInvalido(){
    // @ts-ignore
    return this.formBusqueda.get('precioMinimo').invalid && this.formBusqueda.get('precioMinimo').touched;
  }

  /*
  Get para obtener el estado de la propiedad 'precioMaximo'
   */
  get precioMaximoInvalido(){
    // @ts-ignore
    return this.formBusqueda.get('precioMaximo').invalid && this.formBusqueda.get('precioMaximo').touched;
  }

  /*
  Configuracion para modal caza oportunidad cuando este está en modo 'cargando'
   */
  configuracionModalCargando(){
    this.confModal.colorLogo = this.validarores.modalOptios.icono.cargando.color;
    this.confModal.logo = this.validarores.modalOptios.icono.cargando.logo;
    this.confModal.mensajeFooter = this.validarores.modalOptios.mensaje.cargando.footer;
    this.confModal.mensajeHeader = this.validarores.modalOptios.mensaje.cargando.header;
  }
  /*
  Configuracion para modal caza oportunidad cuando ocurre un 'error'
   */
  configuracionModalError(){
    this.confModal.colorLogo = this.validarores.modalOptios.icono.error.color;
    this.confModal.logo = this.validarores.modalOptios.icono.error.logo;
    this.confModal.mensajeFooter = this.validarores.modalOptios.mensaje.error.footer;
    this.confModal.mensajeHeader = this.validarores.modalOptios.mensaje.error.header;
  }
  /*
  Configuracion para modal caza oportunidad cuando la acción se realiza con 'exito'
   */
  configuracionModalExito(){
    this.confModal.colorLogo = this.validarores.modalOptios.icono.exito.color;
    this.confModal.logo = this.validarores.modalOptios.icono.exito.logo;
    this.confModal.mensajeFooter = this.validarores.modalOptios.mensaje.exito.footer;
    this.confModal.mensajeHeader = this.validarores.modalOptios.mensaje.exito.header;
  }

  updateValue(value: string, field: string) {
    let val = parseFloat(this.convertir( value )+'');
    //console.log(val);
    if (Number.isNaN(val)) {
      val = 0;
    }

    if (field == 'precioMinimo') {
      // @ts-ignore
      this.formBusqueda.patchValue({
        precioMinimo: formatCurrency(val, 'en-US', getCurrencySymbol('USD', 'narrow'))
      });
    } else {
      // @ts-ignore
      this.formBusqueda.patchValue({
        precioMaximo: formatCurrency(val, 'en-US', getCurrencySymbol('USD', 'wide'))
      });
    }
  }

  convertir(value: string) {
    return Number(value.replace(/[^0-9\.]+/g,""));
  }
  onChange(newValue) {
    console.log(newValue);
    this.searchService.obtenerTipoInmuebleFiltrados(newValue).subscribe( (data) => {
    console.log(this.selectedItem);
    this.selectedItem = data.respuesta;
    console.log(this.selectedItem);
    });

    /*this.searchService.obtenerEstadoFiltro(newValue).subscribe( (data) => {
      console.log("tipo venta"+this.formBusqueda.get('tipoVenta').value);
        console.log("estados seleccionados"+this.selectedEstado);
      this.selectedEstado = data.respuesta;
      console.log(this.selectedEstado);
      });*/

}
onChangeInmueble(newValue) {
  console.log("tipo venta"+this.formBusqueda.get('tipoVenta').value);

  this.tipoVenta=this.formBusqueda.get('tipoVenta').value;
  console.log("tipo de datos:"+typeof this.tipoVenta);
  
  console.log("entra en el servicio");
  console.log("venta:"+String(this.formBusqueda.get('tipoVenta').value));
  console.log("tipo de dato"+typeof String(this.formBusqueda.get('tipoVenta').value));
  console.log("newValue:" +typeof newValue);
  this.searchService.obtenerEst(this.formBusqueda.get('tipoVenta').value,this.convertir(newValue)).subscribe( (data) => {
  
    console.log("entra en el ONchange:"+data.respuesta);

  this.selectedEstado = data.respuesta;
  
  console.log("estados seleccionados"+this.selectedEstado);
  console.log(this.selectedEstado);
  });

}

}
