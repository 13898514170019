import { Directive, ElementRef } from '@angular/core';
import { CarruselComponent } from '../pages/carrusel/carrusel.component';

@Directive({
  selector: '[appDomChange]'
})
export class DomChangeDirective {

  observer: MutationObserver;
  constructor( private elRef: ElementRef, private carrusel: CarruselComponent ) {}

  ngAfterViewInit() {
    //console.log('dom');
    this.observer = new MutationObserver( mutations => {
      mutations.forEach( function(mutation) {
      });
      this.carrusel.load();
    });

    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

}
