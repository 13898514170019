import { Injectable } from '@angular/core';
import {HttpHeaders,HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { InmuebleResponse } from '../models/InmueblesResponse';
import { NotificacionInmueble } from '../models/InmuebleSearch.model.response';
import {AppConfig} from '../AppConfig';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DetalleInmuebleService {
  endpoint: string = '';

  constructor(private http: HttpClient, private appConfig: AppConfig) {
    this.endpoint = this.appConfig.getConfig('base_url');
  }

  headers = new HttpHeaders({'Content-Type':'application/json'});
  headersPDF = new HttpHeaders({'Content-Type':'application/json'});
  endopointMaps = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
  apiKey = environment.constante;

  /**
   * Función para obtener el detalle inmueble a partir del folio del imueble
   * @param folio del inmueble a buscar
   * @returns {Observable<Object>} respuesta del servicio
   */
  getDetalleInmueble(folio:string): Observable<any>{
    const url = `${this.endpoint}/bnt/inmuebles/detalle/${ folio }`;
    return this.http.get(url,{headers: this.headers}).pipe(map ( (data: any) => {
      return data.respuesta;
    }));
  }

  saveNotificacionInmueble(notificacion: NotificacionInmueble): Observable<InmuebleResponse> {
    return this.http.post<any>
    (`${this.endpoint}/bnt/inmuebles/notificacion`, notificacion, {headers: this.headers});
  }

  getFichaTecnica(folio: string): Observable<any> {
    return this.http.post(`${this.endpoint}/bnt/inmuebles/fichaTecnica`, folio, { headers: this.headersPDF }).pipe(
      map( ( response: any ) => {
        //console.log(response);
        const byteArray = new Uint8Array(atob(response.body).split('').map(char => char.charCodeAt(0)));
        response.body = new Blob([byteArray], {type: 'application/pdf'});
        return response;
      })
    )
  }

  /**
   * Funcion para obtener coordenadas de una direccion
   * @param direccion de la cual se quieren obtener las coordenadas
   * @returns {Observable<LatLng>} objeto con la latitud y logitud de la direccion dada
   */
  getLocation(direccion: string): Observable<any>{
    return this.http.get(`${this.endopointMaps}${direccion}${this.apiKey}`).pipe( map ( (data:any) => {
      //console.log(data.results);
      return data.results[0].geometry.location;
    }));
  }

}
