export class RecomendarInmuebleModel {

    remitente: string;
    destinatario: string;
    mensaje: string;
    folio: string;
    nombreContacto: string;
    correoContacto: string;

    constructor() { }

}
