import { Gerente } from './contactar-agente.model';
export class CargaInicial {
    tiposInmuebles: TipoInmueble[];
    estados: Estado[];
    tiposVenta: string[] = [];
}

export class BusquedaCiudad {
    ciudades: Ciudad[] = [];
}

export class BusquedaColonia {
    colonias: string[] = [];
}

export class Estado {
    idEstado: number;
    nombre: string;
    territorial: string;
    abreviatura: string;
    idRegion: number;
    idEstadoCobertura: number;
    idZonaRegional: number;
}

export class Ciudad {
    idCiudad: number;
    idEstado: Estado;
    nombre: string;
    idEstadoCobertura: number;
}

export class TipoVenta{
    tipoVenta : string;
}
export class TipoInmueble {
    idTipoInmueble: number;
    nombre: string;
    ctaBco: string;
    ctaFidAdj: string;
    ctaFidDacion: string;
    ctaFidEsp: string;
}

export class BusquedaInmueble {
    idEstado: number;
    idTipoInmuble: number;
    listaCiudades: number[];
    listaColonias: string[];
    palabraClave: string;
    precioMinimo: number;
    precioMaximo: number;
    terreno: number;
    contruccion: number;
    folio: string;
    tipoVenta: number;
}

export class NotificacionInmueble {
    folio: string;
    email: string;
}

export class Promotor {
    idPromotor: number;
    Domicilio: string;
    email: string;
    fechaAlta: string;
    idTipoPersona: number;
    nombre: string;
    nombreAsistente: string;
    rfc: string;
    telefono: string;
    usuario: string;
    contrasena: string;
    fechaBaja:string;
    causaBaja: string;
    existeContrato: boolean;
    comentarios: string;
    gerenteEntity: Gerente;
}
