import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { EstaticoService } from '../../services/estatico.service';
import { AppConfig } from '../../AppConfig';
declare var $: any;
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  @ViewChildren('allThese') things: QueryList<any>;
  banner: string;
  constructor( private estaticoServ: EstaticoService,
    private appConfig: AppConfig ) {
      //this.banner = "http://localhost/dashboard/carrusel/carrusel.html";
      this.banner = `${this.appConfig.getConfig('iplanet')}${this.appConfig.getConfig('endpoint.paths.estaticos')}${this.appConfig.getConfig('endpoint.banner')}`;
  }

  ngAfterViewInit() {
    this.things.changes.subscribe(t => {
      $('.carousel').carousel({
        interval: this.appConfig.getConfig('time.banner')
      });
    })
  }

  ngOnInit() {
  }

}
