import { Estado, Ciudad, Promotor } from './InmuebleSearch.model.response';
export class ContactarAgente {
    idSolicitud: number = null;
    idStatusSolicitudDetalle: number = null;
    idTipoSolicitud: number;
    nombreCliente: string = "";
    correoECliente: string = "";
    telCasaCliente: string = "";
    telOficinaCliente: string = "";
    idPrefContacto: number;
    motivoContactoEntity: MotivoContacto = new MotivoContacto();
    comentarioCliente: string = "";
    clienteEntity: Cliente = new Cliente();
    inmuebleEntity: Inmueble = new Inmueble
    gerenteEntity: Gerente = new Gerente();
    promotorEntity: Promotor = new Promotor();
    fechaCreacion: string = "";
    fechaUltimaAct: string = "";
    idDetalleStatus: number = null;
    comentarioInt: string = null;
    idEstado: number;
    idCiudad: number;
    idUsuario: number;
    telCelularCliente: string = "";
    idStatusSolicitud: number = null;
}

export class Gerente {
    idGerente: number;
}

export class Inmueble {
    idInmueble: number;
}

export class MotivoContacto {
    idMotivoContacto: number;
    nombre: string;
    descripcion: string;
}

export class Cliente {
    idCliente: number = null;
    estadoEntity: Estado;
    codigoActivacion: string;
    email: string;
    emailAlterno: string;
    fechaAlta: string;
    ciudadEntity: Ciudad;
    idEstatus: string;
    idSexo: string;
    usuarioEntity: Usuario;
    nombreCliente: string;
    telefonoCasa: string;
    telefonoOficina: string;
    propietarioEntity: Propietario;
    contrasena: string;
    comentarios: string;
    activado: number;
}

export class Usuario {
    idUsuario: number;
    email: string;
    idRol: number;
    password: string;
    usuario: string;
    idUsuarioSibad: number;
    nombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
}

export class Propietario {
    idEmpSap: number;
    nombre: string;
}