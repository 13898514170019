import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../../services/search.service';
import { BusquedaInmueble } from '../../models/InmuebleSearch.model.response';
import { InmuebleBusqueda } from '../../models/inmueble.busqueda';
import { DetalleInmuebleService } from '../../services/detalle-inmueble.service';
import { DetalleInmuebleModel } from '../../models/detalle-inmueble.model';
import { Subscription } from 'rxjs';
import { AppConfig } from '../../AppConfig';

import { PlatformLocation } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecomendarInmuebleService } from '../../services/recomendar-inmueble.service';
import { RecomendarInmuebleModel } from '../../models/recomendar-inmueble.model';
import { ValidadoresCazaOportunidadService } from '../../services/validadoresCazaOportunidad.service';
import { NotificacionInmueble } from '../../models/InmuebleSearch.model.response';
import { InmuebleResponse } from '../../models/InmueblesResponse';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';




@Component({
  selector: 'app-inmuebles',
  templateUrl: './inmuebles.component.html',
  styleUrls: ['./inmuebles.component.css']
})
export class InmueblesComponent implements OnInit {
  buscar: any;
  like: boolean = true;
  inmuebles: InmuebleBusqueda[] = [];
  paginador: any;
  detalle: DetalleInmuebleModel;
  mostrar: boolean = false;
  mensajeError: string;
  executed: boolean = false;
  paginaActual: number;
  state: any;
  subscription: Subscription;
  paginas: number[];
  cargandoFicha = false;
  desde: number;
  hasta: number;
  noPaginas: number[] = [];
  listo: boolean = false;
  constructor(private routes: Router,
    private detalleService: DetalleInmuebleService,
    private searchServ: SearchService,
    private activatedRoute: ActivatedRoute,
    private appConfig: AppConfig) {
    this.mensajeError = '';
  }

  ngOnInit() {
    this.detalle = new DetalleInmuebleModel();
    this.subscription = this.activatedRoute.queryParamMap.subscribe(params => {
      this.paginaActual = +localStorage.getItem('page');
      if (!this.paginaActual) {
        this.paginaActual = 0;
      }

      this.buscar = JSON.parse(atob(params.get('buscar')));
      this.obtenerInmuebles(this.buscar, this.paginaActual);
    })
  }

  cambiarLike(): void {
    this.like = !this.like;
  }

  obtenerInmuebles(buscar: BusquedaInmueble, page: number): void {
    this.mensajeError = '';
    this.searchServ.obtenerInmuebles(buscar, page).subscribe(response => {
      this.paginador = response.respuesta;
      this.inmuebles = response.respuesta.content;
      //this.inmuebles = this.inmuebles.filter( inm => inm.precio > 1.0);
      this.initPaginador();
      this.listo = true;
      if (this.inmuebles.length == 0) {
        this.mensajeError = 'No se encontraron resultados.'
      }
    });
  }


  public obtenerFichaTecnica(folio: string) {
    this.cargandoFicha = true;
    this.detalleService.getFichaTecnica(folio).subscribe(response => {
      saveAs(response.body, response.filename);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Descargado correctamente',
        showConfirmButton: false,
        timer: 1500
      })
      this.cargandoFicha = false;
    });
    
  }

  public detalleInmueble(folio: string) {
    localStorage.setItem('page', `${this.paginaActual}`);
    this.routes.navigate(['/detalle', folio]);
  }

  public contactarAgente(folio: string) {
    this.detalleService.getDetalleInmueble(folio).subscribe(response => {
      if (response.inmueble) {
        this.setData(response.inmueble);
      }
    })
  }

  private setData(data: any): void {
    this.detalle.folio = data.folio;
    this.detalle.emailGerente = data.idPromotor.gerenteEntity.email;
    this.detalle.promotor = data.idPromotor;
    this.detalle.inmueble = data;
  }

  public getUrl(): string {
    return this.appConfig.getConfig('base_url');
  }

  irPagina(page: number): void {
    this.paginaActual = page;
    localStorage.setItem('page', `${this.paginaActual}`);
    this.obtenerInmuebles(this.buscar, page);
  }

  public initPaginador(): void {
    this.paginas = [];
    this.desde = Math.min(Math.max(1, this.paginador.number - 4), this.paginador.totalPages - 5);
    this.hasta = Math.max(Math.min(this.paginador.totalPages, this.paginador.number + 4), 6);
    if (this.paginador.totalPages > 5) {
      this.paginas = Array(this.hasta - this.desde + 1).fill(0).map((_valor, indice) => indice + this.desde);
    } else {
      this.paginas = Array(this.paginador.totalPages).fill(0).map((_valor, indice) => indice + 1);
    }
  }
}
