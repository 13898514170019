import { Injectable } from '@angular/core';
import { AppConfig } from '../AppConfig';

@Injectable({
  providedIn: 'root'
})
export class LasmasbuscadasService {

  constructor(private appConfig: AppConfig ) { }

  public getLasMasBuscadas():any {
    return (`${this.appConfig.getConfig('iplanet')}${this.appConfig.getConfig('endpoint.paths.estaticos')}${this.appConfig.getConfig('endpoint.masbuscadas')}`);
  }
}
