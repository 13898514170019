import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-redes',
  templateUrl: './redes.component.html',
  styleUrls: ['./redes.component.css']
})
export class RedesComponent implements OnInit {
  url: string;
  constructor() { }

  ngOnInit() {
    $(document).ready(function() {
      $('#value_btn').text('hidden');
      $('.selector_cerrar').click(function() {
        var value = $('#value_btn').text();
        if (value == 'hidden') {
          $('.socialFixed').animate({
            right: '-30px'
          }, 500);
          $('.btnCerrarRedes').animate({
            right: '40px'
          }, 500);
          $('.icon-indicador').css({ 'transform': 'rotate(-90deg)'});
          $('#value_btn').text('show');
        } else {
          $('.socialFixed').animate({
            right: '-100px'
          }, 500);
          $('.btnCerrarRedes').animate({
            right: '-30px'
          }, 500);
          $('.icon-indicador').css({ 'transform': 'rotate(90deg)'});
          $('#value_btn').text('hidden');
        }
      })
    });
  }

  public redSeleccionada( url: string ): void {
    this.url = url;
  }

}
