import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnChanges {

  @Input() url: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges( changes: SimpleChanges ): void {
    let urlNavegacion = changes['url'];
  }

  public navegar( url: string ) : void {
    window.location.href = url;
  }

}
