import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../AppConfig';

@Injectable({
  providedIn: 'root'
})
export class EstaticoService {

  private httpHeaders = new HttpHeaders( {'Content-Type': 'text/plain'} )
  private endpoint: string;
  private endpoint_config: string;
  constructor( private http: HttpClient, private config: AppConfig ) { 
    this.endpoint = `${this.config.getConfig('base_url_estaticos')}${this.config.getConfig('endpoint.carrusel')}`
    this.endpoint_config = `${this.config.getConfig('base_url_config')}`; 
  }


  public obtenerBanner(): Observable<any> {
    return this.http.get(`${this.config.getConfig('base_url')}${this.config.getConfig('endpoint.paths.estaticos')}${this.config.getConfig('endpoint.banner')}`,{ headers: this.httpHeaders, responseType: 'text'});
  }

  public obtenerRecursoCarrusel(): Observable<any> {
    return this.http.get(`${this.endpoint}`, { headers: this.httpHeaders, responseType: 'text'});
  }

  public obtenerRecursoEstatico( nombre: string ): Observable<any> {
    return this.http.get(`${this.config.getConfig('base_url')}${this.config.getConfig('endpoint.paths.estaticos')}${nombre}`, { headers: this.httpHeaders, responseType: 'text'});
  }


  public obtenerRecursoEstatico2( nombre: string ): Observable<any> {
    return this.http.get(`${this.config.getConfig('base_url_estaticos')}/tmp/${nombre}`, { headers: this.httpHeaders, responseType: 'text'});
  }
}
