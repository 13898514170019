import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { FiltroComponent } from './pages/filtro/filtro.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InmuebleInterceptor } from './services/interceptors/inmueble.interceptor';
import { ModalComponent } from './components/modal/modal.component';
import { CarruselComponent } from './pages/carrusel/carrusel.component';
import { BannerComponent } from './pages/banner/banner.component';
import { DomChangeDirective } from './directives/dom-change.directive';
import { SearchComponent } from './pages/search/search.component';
import { DetalleInmuebleComponent } from './pages/detalle-inmueble/detalle-inmueble.component';

//Mapas
import { AgmCoreModule } from '@agm/core';
import { InmueblesComponent } from './pages/inmuebles/inmuebles.component';
import { PaginadorComponent } from './components/paginador/paginador.component';
import { ContactarAgenteComponent } from './pages/contactar-agente/contactar-agente.component';
import { AppConfig } from './AppConfig';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { RouterTransformDirective } from './directives/router-transform.directive';
import { EstaticoGenericoComponent } from './pages/estatico-generico/estatico-generico.component';
import { RedesComponent } from './components/redes/redes.component';
import { DatePipe } from '@angular/common';
import { DirectorioContactoComponent } from './pages/directorio-contacto/directorio-contacto.component';
import { SafePipePipe } from './pipes/safe-pipe.pipe';
import { PopUpComponent } from './pages/pop-up/pop-up.component';
import { LasmasbuscadasComponent } from './pages/lasmasbuscadas/lasmasbuscadas.component';

import { RouterModule } from '@angular/router';
import { WhatsappComponent } from './pages/whatsapp/whatsapp.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    FiltroComponent,
    ModalComponent,
    CarruselComponent,
    BannerComponent,
    DomChangeDirective,
    RouterTransformDirective,
    SearchComponent,
    InmueblesComponent,
    PaginadorComponent,
    DetalleInmuebleComponent,
    ContactarAgenteComponent,
    SafeHtmlPipe,
    EstaticoGenericoComponent,
    RedesComponent,
    DirectorioContactoComponent,
    SafePipePipe,
    PopUpComponent,
    LasmasbuscadasComponent,
    WhatsappComponent
  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB6vikainiD2mwlzhAPBxGEMO1qDwNSsAM'
    })
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: InmuebleInterceptor, multi: true },
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
