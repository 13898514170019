import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EstaticoService } from '../../services/estatico.service';
import { Location } from '@angular/common';
import {AppConfig} from '../../AppConfig';

@Component({
  selector: 'app-estatico-generico',
  templateUrl: './estatico-generico.component.html',
  styleUrls: ['./estatico-generico.component.css']
})
export class EstaticoGenericoComponent implements OnInit {

  html: string;
  nombre: string;
  lista: string[] = ['consejos.html', 'directorio.html', 'mas.html', 'quienes.html'];
  listo:boolean = false;
  constructor( private activateRoute: ActivatedRoute, private estaticoServ: EstaticoService,
    private location: Location, private config: AppConfig) {
      if (this.listo){
        this.listo = false;
      }
  }

  ngOnInit() {
    this.activateRoute.paramMap.subscribe( params => {
      let nombre = params.get('pagina');
      let urlBaseIplanet = "https://www.inmueblesbanorte.com.mx/img_inmuebles/cmsFiles/";
      let urlBaseWebLogic = "http://localhost:7001/Inmuebles-0.0.1/config.d/static/tmp/";

      if(this.lista.indexOf(nombre) >= 0) {
        //this.html = urlBaseWebLogic + nombre;
        this.html = `${this.config.getConfig('base_url_estaticos')}/tmp/${nombre}`;
        this.listo = true;
      } else {
        //this.html = urlBaseIplanet + nombre;
        this.html = `${this.config.getConfig('iplanet')}${this.config.getConfig('endpoint.paths.estaticos')}${nombre}`;
        this.listo = true;
      }

      /*if(this.lista.indexOf(nombre) >= 0) {
        console.log("Entró al if")
        this.estaticoServ.obtenerRecursoEstatico2( nombre ).subscribe( response => {
          this.html = response;
          this.listo = true;
        });
      } else {
        console.log("entró al else")
        this.listo = true;
        this.estaticoServ.obtenerRecursoEstatico( nombre ).subscribe( response => {
          this.html = response;
          this.listo = true;
        });
      }*/
    });
  }

}
