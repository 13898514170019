import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  etiqueta: string = '<a></a>';

  lista: any[] = [
    { id:1, nombre: 'Juan'},
    { id:2, nombre: 'test'}
  ];
  value: any;
  constructor() { }

  ngOnInit() {
  }

  listSeleccionados: string[] = [];
  seleccionado( item: any, isCom? ) {
    //console.log(item);
    if ( this.listSeleccionados.includes( item ) && !isCom ) {
      this.listSeleccionados = this.listSeleccionados.filter( (it) => {
        return it !== item && it !== '';
      });
    } else if ( !this.listSeleccionados.includes( item ) && item.length > 0 ) {
      this.listSeleccionados.push( item );
    }
  }

  public changeValue(e) {
    //console.log(e);
  }

}
