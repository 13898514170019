import { Component, OnInit } from '@angular/core';
import { InmuebleResponse } from 'src/app/models/InmueblesResponse';
import { ConfiguracionModel } from 'src/app/models/configuracion.model';
import { ConfiguracionService } from 'src/app/services/configuracion.service';


@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.css']
})
export class WhatsappComponent implements OnInit {
  configuracion:ConfiguracionModel;
  response:InmuebleResponse;
  respuesta:any;
  detalle:number;
  descripcion:string;
  botonOcultar: boolean;
  constructor(private configuracionService: ConfiguracionService) {

    console.log("Iniciando componente whatsapp");
    this.configuracionService.consultarConfiguracion("whatsapp").subscribe( data => {
        //console.log(data);
      if (data.configuracion != null) {
        this.configuracion = data.configuracion;
        //console.log(this.configuracion);
      } 
      if (data.respuesta.detalle==1) {
        //console.log("boton habilitado");
        this.botonOcultar=true;
      }else if(data.respuesta.detalle==0){
        //console.log("boton deshabilitado");
        this.botonOcultar=false;
      }
      /*if (data.respuesta.descripcion=="Habilitado") {
        console.log("boton habilitado");
        this.botonOcultar=true;
      }else if(data.respuesta.descripcion=="Deshabilitado"){
        console.log("boton deshabilitado");
        this.botonOcultar=false;
      }*/
    })
   }
  

  ngOnInit() {
  }



}

