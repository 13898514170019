import { Component, OnInit } from '@angular/core';
import {DetalleInmuebleService} from '../../services/detalle-inmueble.service';
import { ActivatedRoute, Router } from '@angular/router';
import {DetalleInmuebleModel} from '../../models/detalle-inmueble.model';
import { PlatformLocation } from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RecomendarInmuebleService} from '../../services/recomendar-inmueble.service';
import {RecomendarInmuebleModel} from '../../models/recomendar-inmueble.model';
import {ValidadoresCazaOportunidadService} from '../../services/validadoresCazaOportunidad.service';
import { NotificacionInmueble } from '../../models/InmuebleSearch.model.response';
import { InmuebleResponse } from '../../models/InmueblesResponse';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-detalle-inmueble',
  templateUrl: './detalle-inmueble.component.html',
  styleUrls: ['./detalle-inmueble.component.css']
})
export class DetalleInmuebleComponent implements OnInit {

  /*
  * Variables para controlar la visibilidad del mapa
  * */
  lat: number;
  lng: number;
  zoom: number = 15;
  folio: string;
  direccion = '';
  buscar:any;

  /*
  * Booleanos para controlar cuando se vana a mostrar los mensajes de error
  * */
  detalleExiste: boolean;
  mapaCargado: boolean;
  mapError = { cabecera: '', mensaje: ''};
  detalleError = {cabecera: '', mensaje: ''};

  detalle: DetalleInmuebleModel;
  cargandoFicha = false;

  //formulario recomendar a un amigo
  formRecomendar = FormGroup;
  recomendarDto: RecomendarInmuebleModel = new RecomendarInmuebleModel();
  mostrarFormRecomendarInmueble: boolean;
  mostrarBtnCerrarModal: boolean;
  confModalRecomendar = {titulo: "", mensajeHeader: "", mensajeFooter: "", logo: "", colorLogo: ""};

/*
* variable para notificacion inmueble
*/
formNotificacion = FormGroup;
payLoadNotificacion: NotificacionInmueble = new NotificacionInmueble();

CANCELAR = 'Cancelar';
ACEPTAR = 'Aceptar';
ENVIAR = 'Enviar';
OK = 'OK';
CODERROR = 'ERR-004';
lblBtnCerrarModal = 'Cancelar';
notificacionModel: NotificacionInmueble = new NotificacionInmueble();
mensajeValidacionEmail: string;
procesandoNotificacion = false;
notificacionGuardada = false;
notificacionFallida = false;
paginaActual:number;
confModal = {titulo: '', mensajeHeader: '', mensajeFooter: '', logo: '', colorLogo: ''};
estatusOps = ["En regularización","Comercializable"];
precioEnActualizacion = 'En Actualización';

imagenes: string[] = [];

  constructor(private detalleService: DetalleInmuebleService,
              private activatedRoute: ActivatedRoute,
              private validarores: ValidadoresCazaOportunidadService,
              private location: PlatformLocation,
              private routes: Router,
              private formBuilder: FormBuilder,
              private recomendarService: RecomendarInmuebleService,
              private validadores: ValidadoresCazaOportunidadService ) {
              this.crearFormRecomendar();
              this.crearFormularioNotificacion();
              this.activatedRoute.queryParams.subscribe( params => {
                this.paginaActual = +params.page;
              })
              /*this.location.onPopState(() => {
                this.routes.navigate(['/inmuebles/page/'+this.paginaActual], {queryParams : {buscar: btoa(JSON.stringify(this.buscar))}, relativeTo: this.activatedRoute});
              });*/
  }

  ngOnInit() {
    this.lblBtnCerrarModal = this.CANCELAR;
    this.mostrarBtnCerrarModal = true;
    this.mostrarFormRecomendarInmueble = true;
    this.detalle = new DetalleInmuebleModel();
    this.detalleExiste = false;
    this.mapaCargado = false;
    this.getDetalleInmueble();
  }

  crearFormRecomendar() {
    // @ts-ignore
    this.formRecomendar = this.formBuilder.group({
      remitente: ['',Validators.required,''],
      destinatario: ['',[Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')],''],
      mensaje: []
    });
  }

  recomendarAUnAmigo() {
    if(!this.formularioRecomendarInmuebleEsValido())
      return;
    //mostrar modal como enviando mail
    this.confModCargando();
    this.mostrarFormRecomendarInmueble = false;
    //se caraga la informacion al objeto que será enviado al servicio
    this.crearPayLoadRecomendarInmueble();
    this.mostrarBtnCerrarModal = false;
    this.recomendarService.recomendarAUnAmigo(this.recomendarDto).subscribe( data => {
      //respuesta desde el servicio
      if (data.respuesta == "Ok") {
        //console.log(`Correo enviado a: ${this.recomendarDto.destinatario}`);
        //console.log(data);
        this.confModExito();
      } else {
        //console.log(`Error al enviar correo electrónico.`);
        this.confModError();
        //console.log(data);
      }
      this.lblBtnCerrarModal = this.ACEPTAR;
      this.mostrarBtnCerrarModal = true;
    }, error => {
      //respuesta de error
      //console.log(error);
      this.confModError();
      this.lblBtnCerrarModal = this.ACEPTAR;
      this.mostrarBtnCerrarModal = true;
    });
  }

  crearPayLoadRecomendarInmueble() {
    // @ts-ignore
    this.recomendarDto.destinatario = this.formRecomendar.get("destinatario").value;
    // @ts-ignore
    this.recomendarDto.remitente = this.formRecomendar.get("remitente").value;
    this.recomendarDto.folio = this.detalle.folio;
    // @ts-ignore
    this.recomendarDto.mensaje = this.formRecomendar.get("mensaje").value;

    this.recomendarDto.nombreContacto = this.detalle.nombreGerente;
    this.recomendarDto.correoContacto = this.detalle.emailGerente;
  }

  formularioRecomendarInmuebleEsValido(): boolean {
    // @ts-ignore
    if(this.formRecomendar.invalid) {
      // @ts-ignore
      Object.values(this.formRecomendar.controls).forEach( control => {
        // @ts-ignore
        control.markAsTouched();
      });
      return false;
    }
    return true;
  }

  abrirModalRecomendarInmueble() {
    this.mostrarFormRecomendarInmueble = true;
  }

  cerrarModalRecomendar() {
    setTimeout(() => {
      // @ts-ignore
      this.formRecomendar.reset();
      this.lblBtnCerrarModal = this.CANCELAR;
      this.mostrarFormRecomendarInmueble = true;
    },1000)
  }

  /**
   * funcion para obtener detalle de inmueble desde el back
   */
  getDetalleInmueble() {
    this.cargandoDet();
    this.activatedRoute.params.subscribe( params => {
      //se obtiene el parametro 'folio' que viene por URL
      this.folio = params['folio'];
      this.detalleService.getDetalleInmueble(this.folio).subscribe( data => {
        //console.log(data);
        if (data.inmueble != null) {
          this.setData(data.inmueble);
          this.imagenes = data.imagenes;
          this.getGeolocation(this.direccion);
          //console.log(this.direccion);
          this.detalleExiste = true;
        } else {
          this.noSeEncontroDet();
          this.detalleExiste = false;
        }
      },error => {
        this.errorDet();
        this.detalleExiste = false;
      })
    })
  }

  /**
   * Función para obtener latitud y longitud de una direccion
   * @param direccion de la cual se quire obtener la localización
   */
  getGeolocation(direccion: string) {
    this.cargandoMap();
    this.detalleService.getLocation(this.removeAccents(direccion)).subscribe( data => {
      //console.log(data);
      this.lat = data.lat;
      this.lng = data.lng;
      this.mapaCargado = true;
    },error => {
      this.errorMap();
      //console.log(error);
    })
  }

  removeAccents(str: string): string {
    return str.replace("Á","A")
      .replace("á","a")
      .replace("É","E")
      .replace("é","e")
      .replace("Í","I")
      .replace("í","i")
      .replace("Ó","O")
      .replace("ó","o")
      .replace("Ú","U")
      .replace("ú","u")
      .replace("Ñ","N")
      .replace("ñ","n");
  }

  /**
   * Función para asiganr la data del detalle del inmueble al objeto tipo Detalle
   * @param data objeto que regresa el back al buscar el detalle de un Inmueble
   */
  setData( data: any) {
    this.direccion = `${ data.colonia}, ${data.idCiudad.nombre}, ${data.idCiudad.idEstado.nombre}.`;
    this.detalle.ciudad = data.idCiudad.nombre;
    this.detalle.direccion = `${data.calle}, ${ data.colonia}, ${data.idCiudad.nombre}, ${data.idCiudad.idEstado.nombre}. CP. ${data.cp}`;
    this.detalle.estado = data.idCiudad.idEstado.nombre;
    //this.detalle.estatus = data.status;
    this.detalle.estatus = (data.comercializable)?'1':'0';
    this.detalle.comercializable = data.comercializable;
    this.detalle.folio = data.folio;
    this.detalle.m2Construccion = data.metrosConstruccion;
    this.detalle.m2Terreno = data.metrosTerreno;
    this.detalle.precio = parseFloat(data.precio) <= 1.0 ? this.precioEnActualizacion : data.precio;
    this.detalle.tipoInmueble = data.idTipoInmueble.nombre;
    this.detalle.descripcion = data.descripcion;
    this.detalle.emailGerente = data.idPromotor.gerenteEntity.email;
    this.detalle.nombreGerente = data.idPromotor.gerenteEntity.nombre;
    this.detalle.promotor = data.idPromotor;
    this.detalle.inmueble = data;
  }

  /**
   * funciones para asignar mensajes de error que se muestran en pantalla
   */
  cargandoDet() {
    this.detalleError.cabecera = 'Cargando...';
    this.detalleError.mensaje = '';
  }
  noSeEncontroDet() {
    this.detalleError.cabecera = `No se encontró un Inmueble con el folio: ${ this.folio}`;
    this.detalleError.mensaje = 'No se encontró registro del inmueble que intenta buscar, favor de revisar el folio.';
  }
  errorDet() {
    this.detalleError.cabecera = 'ERROR 500';
    this.detalleError.mensaje = 'No se puede establecer comunicación con el servidor, intente más tarde por favor.';
  }
  cargandoMap() {
    this.mapError.cabecera = 'Cargando...';
    this.mapError.mensaje = 'Buscando la dirección del inmueble...';
  }
  errorMap() {
    this.mapError.cabecera = 'Error';
    this.mapError.mensaje = 'No se pudo conectar con Google Maps.';
  }

  /*
 Get para obtener el estado de la propiedad 'correo'
  */
  get destinatarioInvalido() {
    // @ts-ignore
    return this.formRecomendar.get('destinatario').invalid && this.formRecomendar.get('destinatario').touched;
  }
  /*
Get para obtener el estado de la propiedad 'correo'
*/
  get remitenteInvalido() {
    // @ts-ignore
    return this.formRecomendar.get('remitente').invalid && this.formRecomendar.get('remitente').touched;
  }

  /*
Configuracion para modal caza oportunidad cuando este está en modo 'cargando'
 */
  confModCargando() {
    this.confModalRecomendar.colorLogo = this.validadores.modalOptios.icono.cargando.color;
    this.confModalRecomendar.logo = this.validadores.modalOptios.icono.cargando.logo;
    this.confModalRecomendar.mensajeFooter = this.validadores.modalOptios.mensaje.cargando.footer;
    this.confModalRecomendar.mensajeHeader = this.validadores.modalOptios.mensaje.cargando.header;
  }
  /*
  Configuracion para modal caza oportunidad cuando ocurre un 'error'
   */
  confModError() {
    this.confModalRecomendar.colorLogo = this.validadores.modalOptios.icono.error.color;
    this.confModalRecomendar.logo = this.validadores.modalOptios.icono.error.logo;
    this.confModalRecomendar.mensajeFooter = this.validadores.modalOptios.mensaje.error.footer;
    this.confModalRecomendar.mensajeHeader = this.validadores.modalOptios.mensaje.error.header;
  }
  /*
  Configuracion para modal caza oportunidad cuando la acción se realiza con 'exito'
   */
  confModExito() {
    this.confModalRecomendar.colorLogo = this.validadores.modalOptios.icono.exito.color;
    this.confModalRecomendar.logo = this.validadores.modalOptios.icono.exito.logo;
    this.confModalRecomendar.mensajeFooter = this.validadores.modalOptios.mensaje.exito.footer;
    this.confModalRecomendar.mensajeHeader = this.validadores.modalOptios.mensaje.exito.header;
  }

  guardarNotificacion() {
    //console.log(this.formNotificacion);
    if (!this.formularioNotificacionValido()) {
      return;
    }
    this.configuracionModalCargando();
    this.procesandoNotificacion = true;
    //console.log('Enviando peticion');
    this.crearPayLoadNotificacion();
    this.lblBtnCerrarModal = this.ACEPTAR;
    this.detalleService.saveNotificacionInmueble(this.payLoadNotificacion).subscribe((data: InmuebleResponse) => {
      //console.log(data);
      //console.log(data.mensaje);
      if (data.codError === this.CODERROR) {
        this.notificacionFallida = true;
        this.configuracionModalErrorMessage(data.respuesta);
        this.procesandoNotificacion = false;
      } else if (data.mensaje === this.OK) {
        this.notificacionGuardada = true;
        this.configuracionModalExito();
        this.procesandoNotificacion = false;
        this.notificacionModel = new NotificacionInmueble();
      } else {
        this.notificacionFallida = true;
        this.configuracionModalError();
        this.procesandoNotificacion = false;
      }
    }, error => {
      //console.log(error);
      this.notificacionFallida = true;
      this.configuracionModalError();
      this.procesandoNotificacion = false;
    });
  }

  formularioNotificacionValido(): boolean {
    // @ts-ignore
    if (this.formNotificacion.invalid) {
      // @ts-ignore
      Object.values(this.formNotificacion.controls).forEach(control => {
        // @ts-ignore
        control.markAsTouched();
        // @ts-ignore
        if (control.errors.hasOwnProperty('pattern')) {
          this.mensajeValidacionEmail = 'Correo no válido';
        } else {
          this.mensajeValidacionEmail = 'Ingresa tu correo electrónico';
        }
      });
      return false;
    }
    return true;
  }

  obtenerFichaTecnica() {
    this.cargandoFicha = true;
    this.detalleService.getFichaTecnica(this.detalle.folio).subscribe( response => {
      saveAs(response.body, response.filename);
      this.cargandoFicha = false;
    });
  }

  configuracionModalCargando() {
    this.confModal.colorLogo = this.validarores.modalOptios.icono.cargando.color;
    this.confModal.logo = this.validarores.modalOptios.icono.cargando.logo;
    this.confModal.mensajeFooter = this.validarores.modalOptios.mensaje.cargando.footer;
    this.confModal.mensajeHeader = this.validarores.modalOptios.mensaje.cargando.header;
  }

  configuracionModalError() {
    this.confModal.colorLogo = this.validarores.modalOptios.icono.error.color;
    this.confModal.logo = this.validarores.modalOptios.icono.error.logo;
    this.confModal.mensajeFooter = this.validarores.modalOptios.mensaje.error.footer;
    this.confModal.mensajeHeader = this.validarores.modalOptios.mensaje.error.header;
  }

  configuracionModalErrorMessage(texto: string) {
    this.confModal.colorLogo = this.validarores.modalOptios.icono.error.color;
    this.confModal.logo = this.validarores.modalOptios.icono.error.logo;
    this.confModal.mensajeFooter = texto;
    this.confModal.mensajeHeader = this.validarores.modalOptios.mensaje.error.header;
  }

  configuracionModalExito() {
    this.confModal.colorLogo = this.validarores.modalOptios.icono.exito.color;
    this.confModal.logo = this.validarores.modalOptios.icono.exito.logo;
    this.confModal.mensajeFooter = this.validarores.modalOptios.mensaje.exito.footer;
    this.confModal.mensajeHeader = this.validarores.modalOptios.mensaje.exito.header;
  }

  crearPayLoadNotificacion() {
    // @ts-ignore
    this.payLoadNotificacion.email = this.formNotificacion.get('correo').value;
    this.payLoadNotificacion.folio = this.folio;
  }

  get correoInvalido() {
    // @ts-ignore
    return this.formNotificacion.get('correo').invalid && this.formNotificacion.get('correo').touched;
  }

  cerrarModalNotificacion() {
    setTimeout(() => {
      // @ts-ignore
      this.formNotificacion.reset();
      this.mensajeValidacionEmail = '';
      this.notificacionGuardada = false;
      this.notificacionFallida = false;
      this.procesandoNotificacion = false;
      this.lblBtnCerrarModal = this.CANCELAR;
    }, 1000);
  }

  crearFormularioNotificacion() {
    // @ts-ignore
    this.formNotificacion = this.formBuilder.group({
      correo: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')], '']
    });
  }

}
