import { Component, OnInit, ElementRef, ViewEncapsulation, PipeTransform, Pipe, Input, ViewChildren, QueryList } from '@angular/core';
import { EstaticoService } from '../../services/estatico.service';
import { AppConfig } from '../../AppConfig';
import { SearchService } from '../../services/search.service';
import { Router } from '@angular/router';
import { InmuebleBusqueda } from '../../models/inmueble.busqueda';

declare var $: any;

@Component({
  selector: 'app-carrusel',
  templateUrl: './carrusel.component.html',
  styleUrls: ['./carrusel.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CarruselComponent implements OnInit {
  @ViewChildren('allTheseThings') things: QueryList<any>;
  observer: any;
  template: string;
  endpoint: string;
  inmuebles: InmuebleBusqueda[] = [];
  urlBase: string;
  esVacia: boolean = false;
  listo: boolean = false;
  constructor( private searchServ: SearchService,
               private routes: Router,
               private appConfig: AppConfig ) {


    this.urlBase = this.appConfig.getConfig('base_url');
    this.searchServ.obtenerMasBuscados().subscribe( response => {
      this.esVacia = false;
      this.inmuebles = response.respuesta;
      this.listo = true;
       if ( this.inmuebles.length == 0 ) {
         this.esVacia = true;
       }
    },err => {
    });
  }

  ngAfterViewInit() {
    this.things.changes.subscribe(t => {
      this.load();
    })
  }

  ngOnInit() {
  }

  @Input()
  set ready(isReady: boolean) {
    if (isReady) {
      //console.log('listo');
    }
  }

  public load() {
    var owl = $('.owl-carousel').owlCarousel({
      items: 4,
      margin: 50,
      autoplay:true,
      autoplayTimeout:this.appConfig.getConfig('time.carrusel'),
      autoplayHoverPause:true,
      autoplaySpeed: 1000,
      responsiveClass:true,
      loop: true,
      dots: false,
      responsive: {
        0: {
          items: 1
        },
        650: {
          items: 2
        },
        900: {
          items: 3
        },
        1200: {
          items: 4
        }
      }
    });
    $('.btnAnterior').click(function() {
      owl.trigger('prev.owl.carousel');
    });

    $('.btnSiguiente').click(function() {
      owl.trigger('next.owl.carousel');
    });
  }

  public direccionar( folio: string ): void {
    //console.log(folio);
    this.routes.navigate(['/detalle', folio]);
  }

}
