import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.css']
})
export class PopUpComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    console.log("Iniciando pop-Up");
  }


  ngAfterViewInit() {
    Swal.fire({
      imageUrl: 'Inmuebles/assets/iconos/Popup_inmuebles.png',
      customClass: 'swal-wide',
      showConfirmButton: false,
      showCloseButton: true,
      background: '#999999'
    });
  }
}
