import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-paginador',
  templateUrl: './paginador.component.html',
  styleUrls: ['./paginador.component.css']
})
export class PaginadorComponent implements OnInit, OnChanges {

  @Input() paginador: any;

  paginas:number[];

  desde: number;
  hasta: number;

  constructor(private routes: Router) { }

  ngOnInit() {

  }

  ngOnChanges( changes:  SimpleChanges ) {
    let paginadorActualizado = changes['paginador'];
    this.initPaginador();
  }

  public initPaginador(): void {
    this.desde = Math.min( Math.max( 1, this.paginador.number - 4 ), this.paginador.totalPages - 5 );
    this.hasta = Math.max( Math.min( this.paginador.totalPages, this.paginador.number + 4 ), 6 );
    if (this.paginador.totalPages > 5) {
      this.paginas = new Array(this.hasta - this.desde + 1).fill(0).map((_valor, indice) => indice + this.desde);
    } else {
      this.paginas = new Array(this.paginador.totalPages).fill(0).map((_valor, indice) => indice + 1);
    }
  }

  public sig_ant(pagina: number): void {
    let ruta = this.routes.url.replace(`/inmuebles/page/${this.paginador.number}`, `/inmuebles/page/${pagina}`);
    this.routes.navigate([ruta]);
  }

}
