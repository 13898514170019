import { Component, OnInit } from '@angular/core';
import {DirectorioContactosService} from '../../services/directorio-contactos.service';
import {Estado} from '../../models/InmuebleSearch.model.response';

@Component({
  selector: 'app-directorio-contacto',
  templateUrl: './directorio-contacto.component.html',
  styleUrls: ['./directorio-contacto.component.css']
})
export class DirectorioContactoComponent implements OnInit {

  //contactos: DirectorioContacto[];
  contactos: any[];
  mensajeError = 'Cargando...';
  paginador: any;
  noPagina: number[] = [];
  estados: Estado[] = [];
  estadosSeleccionados: number[] = [0];
  estadosSeleccionadosNombres: Estado[] = [];
  requestDirectorio = {
    page: 0,
    estados : [0]
  };
  a = false;

  constructor(
      private directorioService: DirectorioContactosService) {}

  ngOnInit() {
    this.obtenerEstados();
    this.cambiarDePagina(this.requestDirectorio);
  }

  obtenerEstados(){
    this.directorioService.getEstados().subscribe(data => {
      //console.log(data);
      this.estados = data;
    },err => {
      this.estados = [];
    })
  }

  crearPaginas(totalPaginas: number){
    this.noPagina = [];
    for(let i = 0; i < totalPaginas; i++){
      this.noPagina.push(i);
    }
  }

  cambiarDePagina(request){
    this.mensajeError="Cargando...";
    //console.log(request);
    this.directorioService.getAllContactos(request).subscribe(data =>{
      //console.log(data);
      if(data.content){
        this.asignarData(data);
      }
    },error => {
      //console.log(error);
      this.mensajeError = "Error al cargar la información."
    })
    this.a = true;
  }

  prepararRequest(page: number){
    this.requestDirectorio.page = page;
    this.requestDirectorio.estados = this.estadosSeleccionados;
    //console.log(this.requestDirectorio);
    this.cambiarDePagina(this.requestDirectorio);
  }

  listarEstados(idEsado: number){
    if(idEsado == 0){
      this.estadosSeleccionados = [0];
      this.estadosSeleccionadosNombres = [];
      return;
    }
    this.estadosSeleccionados = this.estadosSeleccionados.filter(this.diferenteDeCero);
    let estaSeleccionado = false;
    this.estadosSeleccionados.forEach( e => {
      if(e == idEsado)
        estaSeleccionado = true;
    })
    if(!estaSeleccionado){
      this.estadosSeleccionados.push(idEsado);
      this.estadosSeleccionados.forEach( e =>{
        this.estados.forEach( es => {
          estaSeleccionado = false;
          this.estadosSeleccionadosNombres.forEach(esn => {
            if(es.nombre == esn.nombre){
              estaSeleccionado = true;
            }
          })
          if(es.idEstado == e && !estaSeleccionado){
            this.estadosSeleccionadosNombres.push(es);
          }
        })
      })
    }
  }

  quitarEstado(idEstado){
    let count = 0;
    for(count;count < this.estadosSeleccionadosNombres.length ; count++){
      if(this.estadosSeleccionadosNombres[count].idEstado == idEstado)
        break
    }
    this.estadosSeleccionadosNombres.splice(count,1);
    this.estadosSeleccionados.splice(count,1);
    if(this.estadosSeleccionados.length < 1)
      this.estadosSeleccionados = [0];
  }

  diferenteDeCero(elemento){
    return elemento != 0;
  }

  asignarData(data:any){
    this.crearPaginas(data.totalPages);
    this.paginador = data;
    this.contactos = data.content;
  }

  mostrarCobertura(idCobertura): string {
    let estado: Estado = null;
    this.estados.forEach(est => {
      if(est.idEstado == idCobertura){
        estado = est;
      }
    });
    return estado.nombre;
  }

}
