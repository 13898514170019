import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {CazaOportunidadModel} from '../models/cazaOportunidad.model';
import {AppConfig} from '../AppConfig';

@Injectable({
  providedIn: 'root'
})
export class CazaOportunidadService {

  headers = new HttpHeaders({'Content-Type':'application/json'});
  //endopoint = 'http://localhost:7001/Inmuebles-0.0.1/bnt/inmuebles/nuevaCazaOportunidad';
  endpoint = '';

  constructor(private http:HttpClient, private appConfig: AppConfig) {
    this.endpoint = appConfig.getConfig('base_url');
  }

  guardarNuevaCazaOportunidad(cazaOportunidadModel: CazaOportunidadModel){
    return this.http.post(`${this.endpoint}/bnt/inmuebles/nuevaCazaOportunidad`,cazaOportunidadModel,{headers:this.headers});
  }

}
